import { Box, Image, Text, Button, Link } from 'theme-ui'
import styles from './BannerCardBlock.module.scss'

const BannerCardBlock = ({
  title,
  preTitle,
  description,
  buttonCtaText,
  author,
  buttonCtaLink,
  desktopImage,
  mobileImage,
  isBodyBackgroundColorDark,
  isBodyTextDark,
}) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: ['column', 'row'],
      width: '100%',
      height: ['auto', '800px', '600px'],
      position: 'relative',
      overflow: 'hidden',
      borderRadius: 8,
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      backgroundColor: 'background',
      marginTop: 4 // Adds space between the grid and this content block
    }}>
    <Box
      sx={{
        flex: ['0 0 auto', '0 0 50%'],
        position: 'relative',
        width: '100%',
        height: '100%', // Adjust height for responsiveness
        margin: 0,
        padding: 0
      }}>
      <Image
        src={desktopImage?.url}
        className={`${styles.desktopImage}`}
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        }}
        alt={desktopImage?.title}
      />
      <Image
        src={mobileImage?.url}
        className={`${styles.mobileImage}`}
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        }}
        alt={mobileImage?.title}
      />
    </Box>
    <Box
      sx={{
        flex: ['0 0 auto', '0 0 50%'],
        padding: '1.5rem 1.5rem 1.5rem 2.8rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: isBodyBackgroundColorDark ? '#1A1A1A' : 'white',
        color: isBodyTextDark ? 'black' : 'white',
        borderLeft: ['none', '2px solid white'],
        margin: 0
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}>
        <Text
          className='font--body-1'
          sx={{
            fontSize: '1.25rem',
            textTransform: 'uppercase',
            fontWeight: 700,
          }}>
          {preTitle}
        </Text>
        <Text
          className="font--heading-2"
          sx={{
            fontFamily: 'var(--font-heading)',
            fontSize: ['2rem', '3rem'],
            fontWeight: 'bold',
            marginTop: 2,
            lineHeight: '3.25rem'
          }}>
          {title}
        </Text>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <Text className="font--body-1" sx={{
          // color: isBodyTextDark ? 'black': 'white',
        }}>
          {description}
        </Text>
        <Text className='font--body-3' sx={{ fontSize: [1, 2], fontStyle: 'italic' }}>By {author}</Text>
        <Link href={buttonCtaLink} target="_self" passHref>
          <Button
            className='font--body-1'
            sx={{
              padding: '0.75rem 2.5rem',
              width: 'fit-content',
              border: `1px solid ${isBodyTextDark ? 'black' : 'white'}`,
              borderRadius: 0,
              gap: '7.96px',
              fontSize: '1.5rem',
              fontWeight: 500,
              lineHeight: '1.5rem',

              color: isBodyTextDark ? 'black' : 'white',
              transition: 'color 0.3s, background-color 0.3s',
              '&:hover': {
                color: isBodyTextDark ? 'white' : 'black',
                backgroundColor: isBodyTextDark ? 'black' : 'white'
              }
            }}>
            {buttonCtaText}
          </Button>
        </Link>
      </Box>
    </Box>
  </Box>
)

export default BannerCardBlock
