import { Grid } from 'theme-ui'
import CardBoxModule from '../CardBox/CardBoxModule'

const CardGrid = ({ items }) => (
  <Grid
    columns={[1, 2]} // 1 column for small screens, 2 columns for larger screens
    gap={'26px'} // Space between boxes
    sx={{
      width: '100%',
      rowGap: '54px'
    }}>
    {items.map((item, index) => (
      <CardBoxModule
        key={index}
        image={item.mainImage}
        desktopImage={item.desktopImage}
        mobileImage={item.mobileImage}
        title={item.title}
        description={item.description}
        buttonCtaLink={item.buttonCtaLink}
        buttonCtaText={item.buttonCtaText}
        isBodyBackgroundColorDark={item.isBodyBackgroundColorDark}
        isBodyTextDark={item.isBodyTextDark}
      />
    ))}
  </Grid>
)

export default CardGrid
