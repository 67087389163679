import Link from 'next/link'
import { Box, Image, Text, Button } from 'theme-ui'
import styles from './CardBoxModule.module.scss'

const CardBoxModule = ({
  title,
  description,
  buttonCtaLink,
  buttonCtaText = '',
  isBodyBackgroundColorDark,
  desktopImage,
  mobileImage,
  isBodyTextDark,
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'background',
        overflow: 'hidden'
      }}>
      <Image
        src={desktopImage?.url}
        className={`${styles.desktopImage}`}
        sx={{
          top: 0,
          left: 0,
          width: '100%',
          height: '400px',
          objectFit: 'cover',
          display: 'block'
        }}
        alt={desktopImage?.title}
      />
      <Image
        src={mobileImage?.url}
        className={`${styles.mobileImage}`}
        sx={{
          top: 0,
          left: 0,
          width: '100%',
          height: '400px',
          objectFit: 'cover',
          display: 'block'
        }}
        alt={mobileImage?.title}
      />
      <Box
        sx={{
          bottom: 0,
          left: 0,
          width: '100%',
          height: '100%',
          padding: '52px 52px 50px 30px',
          backgroundColor: isBodyBackgroundColorDark? 'black': 'white',
          color: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '40px'
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '12px'
          }}>
        <Text sx={{ 
          color: isBodyTextDark ? 'black' : 'white',
          fontSize: 4, lineHeight: '100%', fontWeight: 700, fontFamily: 'var(--font-heading)' }}>
          {title}
        </Text>
        <Text className='font--body-1' sx={{
          color: isBodyTextDark ? 'black' : 'white',
        }}>
          {description}
        </Text>
        </Box>
        <Link href={buttonCtaLink} target="_self" passHref>
          <Button
            className='font--body-1'
            sx={{
              padding: '0.75rem 2.5rem',
              border: `1px solid ${isBodyTextDark ? 'black' : 'white'}`,
              borderRadius: 0,
              fontSize: '1.5rem',
              fontWeight: 500,
              lineHeight: '1.5rem',
              color: isBodyTextDark ? 'black' : 'white',
              transition: 'color 0.3s, background-color 0.3s',
              '&:hover': {
                color: isBodyTextDark ? 'white' : 'black',
                backgroundColor: isBodyTextDark ? 'black' : 'white'
              }
            }}>
            {buttonCtaText}
          </Button>
        </Link>
      </Box>
    </Box>
  )
}

export default CardBoxModule
