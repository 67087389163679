import Metadata from '~/components/Metadata'

import { Box } from 'theme-ui'
import { getPage } from 'graphql-cms/pages'
import HeroModule from '~/components/Content/Hero/HeroModule'
import CardGrid from '~/components/Content/CardGrid/CardGrid'
import BannerCardBlock from '~/components/Content/BannerCardBlock/BannerCardBlock'

export async function getStaticProps({ locale }) {
  const page = await getPage({ locale, slug: 'new-homepage' })

  return {
    props: {
      page
    }
  }
}

const MainHomepage = ({ page }) => {
  const { items } = page.bodyCollection || []
  return <>
    <Metadata title={page.name} />
    <div>
      {page.heroModule && <HeroModule data={page.heroModule} />}
      <Box
        sx={{
          padding: '40px',
          rowGap: '54px',
          display: 'flex',
          flexDirection: 'column'
        }}>
        <CardGrid items={items} />
        {page.largeContentBlock && (
          <BannerCardBlock {...page.largeContentBlock} />
        )}
      </Box>
    </div>
  </>
}

export default MainHomepage;
