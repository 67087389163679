/** @jsxImportSource theme-ui */
import Link from 'next/link'
import { useState, useEffect } from 'react'

import styles from './Hero.module.scss'
import { Box, Button, Text } from 'theme-ui'

import { TimerCountdown } from '~/components/Generic/TimerCountdown'
import ResponsiveImage from '~/components/Common/ResponsiveImage'

const HeroModule = ({ data }) => {
  const {
    eyebrowText,
    titleText,
    bodyText,
    isBodyTextDark,
    buttonCtaText,
    buttonCtaLink,
    isButtonCtaColorDark,
    desktopImage,
    mobileImage,
    collection: { lotsStartClosingAt, lotsEndClosingAt }
  } = data
  const [isTimerVisible, setIsTimerVisible] = useState(false)
  const [isInfoMessageVisible, setIsInfoMessageVisible] = useState(false)
  const [isEndingSoon, setIsEndingSoon] = useState(false)

  const auctionStartDateTimestamp = new Date(lotsStartClosingAt)
  const auctionEndDateTimestamp = new Date(lotsEndClosingAt)

  // After countdown timer reaches 0, show the info message
  useEffect(() => {
    const interval = setInterval(() => {
      const currentTimestamp = new Date()

      // If the time difference is less than 24 hour, show the countdown timer
      setIsTimerVisible(
        auctionStartDateTimestamp - currentTimestamp < 86400000 &&
          currentTimestamp < auctionEndDateTimestamp
      )
      // If the first auction is already closed, show info message
      setIsInfoMessageVisible(
        currentTimestamp > auctionStartDateTimestamp &&
          currentTimestamp < auctionEndDateTimestamp
      )
      // If the time difference is less than 1 hour, apply the 'ending-soon' class
      setIsEndingSoon(
        auctionStartDateTimestamp - currentTimestamp < 3600000 &&
          currentTimestamp < auctionEndDateTimestamp
      )
    }, 1000)

    return () => clearInterval(interval)
  }, [])
  return (
    <div className={styles.wrapper}>
      <ResponsiveImage
        quality={100}
        image={desktopImage}
        className={`${styles.backgroundImage} ${styles.desktopImage}`}
      />
      <ResponsiveImage
        image={mobileImage}
        className={`${styles.backgroundImage} ${styles.mobileImage}`}
      />
      <Box
        className={styles.textWrapper}
        sx={{
          color: isBodyTextDark ? 'black' : 'white'
        }}>
        <Text sx={{ fontSize: '1.5rem', fontWeight: 700 }}>{eyebrowText}</Text>
        {isInfoMessageVisible ? (
          <Text
            sx={{
              fontSize: '1.375rem',
              fontWeight: 700,
              color: isEndingSoon && 'red'
            }}>
            Lots are closing
          </Text>
        ) : isTimerVisible ? (
          <Text
            sx={{
              fontSize: '1.375rem',
              fontWeight: 700,
              color: isEndingSoon && 'red'
            }}>
            Lots start closing in:{' '}
            <TimerCountdown
              fallBackText={'Soon'}
              endDate={auctionStartDateTimestamp}
              maxTimerLength={600000 * 6 * 24}
            />
          </Text>
        ) : null}
        <Text sx={{ fontSize: 4, lineHeight: '100%', fontWeight: 700 }}>
          {titleText}
        </Text>
        <Text
          sx={{
            fontSize: '1.5rem',
            lineHeight: 1.5,
            maxWidth: 500,
            py: 1,
            fontFamily: 'secondary',
            fontWeight: 400
          }}>
          {bodyText}
        </Text>
        { buttonCtaLink && buttonCtaText && 
        <Link href={buttonCtaLink} target="_blank" passHref>
          <Button
            sx={{
              px: '2.5rem',
              py: '0.75rem',
              width: 'fit-content',
              border: `1px solid ${isButtonCtaColorDark ? 'black' : 'white'}`,
              borderRadius: 0,
              fontSize: '1.5rem',
              fontWeight: 500,
              lineHeight: 'initial',
              color: isButtonCtaColorDark ? 'black' : 'white',
              transition: 'color 0.3s, background-color 0.3s',
              '&:hover': {
                color: isButtonCtaColorDark ? 'white' : 'black',
                backgroundColor: isButtonCtaColorDark ? 'black' : 'white'
              }
            }}>
            {buttonCtaText}
          </Button>
        </Link>
        }
      </Box>
    </div>
  )
}

export default HeroModule
