import { useState } from 'react'
import { useInterval } from '~/hooks/utils/timers'

export const TimerCountdown = ({
  endDate,
  fallBackText,
  maxTimerLength = undefined // The maximum timer length in milliseconds
}) => {
  const [timeInterval, setTimeInterval] = useState('')
  const countdownDate = new Date(endDate).getTime()
  const showAfter = //Only display the timer after this time
    maxTimerLength !== undefined ? countdownDate - maxTimerLength : undefined

  const calculateTimeInterval = () => {
    if (countdownDate > new Date()) {
      const timeBetween = countdownDate - new Date()
      const days = Math.floor(timeBetween / (1000 * 60 * 60 * 24))
      const hours = Math.floor(
        (timeBetween % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      const minutes = Math.floor((timeBetween % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((timeBetween % (1000 * 60)) / 1000)
      return `${days >= 1 ? days + 'd' : ''} ${hours}:${
        minutes >= 10 ? minutes : `0${minutes}`
      }:${seconds >= 10 ? seconds : `0${seconds}`}`
    } else {
      return fallBackText || 'Soon'
    }
  }
  useInterval(() => {
    setTimeInterval(calculateTimeInterval())
  }, 1000)
  if (!endDate) return null
  if (showAfter && showAfter > new Date()) return null
  return (
    <>
      <span>{timeInterval}</span>
    </>
  )
}
